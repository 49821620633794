<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-03 15:17:12
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2022-12-04 21:07:16
-->
<template>
  <div class="box">
    <div class="bgs"></div>
    <div class="content">
      <div class="module module-banner">
        <div class="search-container">
          <input v-model="keywords" placeholder="输入关键词搜索" type="text" @keyup.enter="toSearch">
          <img class="search-icon" @click="toSearch" src="../../assets/images/icon-search.png" alt="">
        </div>
      </div>
      <div class="base">
        <div class="top-menu">
          <div class="item" v-for="(item, index) in tap" :key="index">
            <div class="title" :class="{active: active == item.id}" @click="tapClick(item)">{{item.name}}</div>
          </div>
        </div>
        <new-list :list="list" :active="active"></new-list>
        <div class="pagin">
          <el-pagination background @current-change="handleCurrentChange" :current-page.sync="page" :page-size="20"
            layout="prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/util/http'
import newList from '@/components/newList.vue';
export default {
  components: { newList },
  data() {
    return {
      keywords: '',
      page: 1,
      list: [],
      total: 0,
      active: 1,
      tap: [
        {name: '资讯', id: 1},
        {name: '题目', id: 2}
      ]
    };
  },
  mounted() {
    this.keywords = this.$route.query.keywords;
    this.getList();
  },
  methods: {
    async getList() {
      var params = {
        keyword: this.keywords,
        page: this.page
      }
      var { data } = await http.post('/api/portal/getList.html', params);
      this.total = data.total;
      this.list = data.data;
    },
    async queryList(){
      var params = {
        keywords: this.keywords,
        page: this.page
      }
      var {data} = await http.post('/api/exam/getQuestions', params);
      this.total = data.total;
      this.list = data.data;
    },
    tapClick(item){
      if(item.id == this.active) return;
      this.active = item.id;
      this.page = 1;
      this.list = [];
      if(item.id == 1) {
        this.getList();
      }else{
        this.queryList();
      }
    },
    goDetail(item){
      this.$router.push({
        name: 'newDetail',
        query:{
          id: item.id
        }
      })
    },
    toSearch() {
      this.page = 1;
      if(this.active == 1){
        this.getList();
      }else{
        this.queryList();
      }
    },
    handleCurrentChange(page) {
      this.page = page;
      if(this.active == 1){
        this.getList();
      }else{
        this.queryList();
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;

  .bgs {
    // position: fixed;
    position: absolute;
    left: 0;
    // top: 80px;
    top: 0;
    width: 100%;
    height: 960px;
    background-image: linear-gradient(to bottom, #FF2F07, #fff);
  }

  .content {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    .module-banner {
      padding: 85px 0;
      padding-bottom: 50px;
      background-size: auto 100%;

      .search-container {
        position: relative;
        margin: 0 auto;
        width: 780px;
        height: 50px;
        padding: 13px 54px 13px 20px;
        background: #ffffff;
        border-radius: 10px;

        input {
          width: 100%;
          height: 100%;
          font-size: 18px;
        }

        .search-icon {
          position: absolute;
          top: 13px;
          right: 24px;
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }

    .woman {
      height: 149px;
      position: absolute;
      left: 20px;
      top: 46px;
    }

    .base {
      width: 100%;
      background-color: #fff;
      position: relative;

      .pagin {
        padding: 40px 0;
        padding-bottom: 180px;
        text-align: center;
      }

      .list {
        padding: 0 30px;

        .item {
          font-size: 16px;
          text-align: left;
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #E8E8E8;
          cursor: pointer;

          .title {
            img {
              height: 20px;
              margin-right: 5px;
              vertical-align: middle;
            }
          }

          .time {
            img {
              height: 14px;
              margin-right: 6px;
              vertical-align: middle;
            }
          }
        }
      }

      .top-menu {
        width: 100%;
        height: 80px;
        border-bottom: 1px solid #E6E6E6;
        display: flex;
        align-items: center;

        .item {
          flex: 1;
          text-align: center;

          .title {
            display: inline-block;
            font-size: 20px;
            padding: 0 22px;
            height: 80px;
            line-height: 80px;
            cursor: pointer;
            position: relative;

            &.active {
              color: #FF6A29;

              &::after {
                content: '';
                width: 100%;
                height: 4px;
                border-radius: 4px;
                background-color: #FF6A29;
                position: absolute;
                left: 0;
                bottom: 0;
              }
            }

            &:hover {
              color: #FF6A29;
            }
          }
        }
      }
    }
  }
}
</style>